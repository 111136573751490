import { ApiWithToken } from "../axios-instance";
import { abstractOrganizationalSurvey, abstractPersonalization } from "./utils";

export const clientService = {
  acceptTerms: {
    uri: "/api/client/accept_terms/",
    post: async () => ApiWithToken.post(clientService.acceptTerms.uri),
  },
  info: {
    uri: "/api/client/info/",
    get: async () => ApiWithToken.get(clientService.info.uri),
    put: async (data: any) => ApiWithToken.put(clientService.info.uri, data),
    delete: async () => ApiWithToken.delete(clientService.info.uri),
  },
  profile: {
    uri: "/api/client/profile/",
    get: async () => ApiWithToken.get(clientService.profile.uri),
    put: async (data: any) => ApiWithToken.put(clientService.profile.uri, data),
  },
  users: {
    uri: "/api/client/users/",
    post: async (data: any) => ApiWithToken.post(clientService.users.uri, data),
    get: async () => ApiWithToken.get("api/client/list_users/"),
    delete: async (id: number) =>
      ApiWithToken.delete("api/client/delete_user/", {
        data: {
          id: id,
        },
      }),
  },
  organizationalSurvey: {
    policy: abstractOrganizationalSurvey("policy"),
    channels: abstractOrganizationalSurvey("channel"),
    training: abstractOrganizationalSurvey("training"),
    good_practices: {
      ...abstractOrganizationalSurvey("good_practices"),
      post: async (code: string | undefined, data: any) =>
        ApiWithToken.post(
          abstractOrganizationalSurvey("good_practices").uri(code),
          data
        ),
      delete: async (code: string | undefined, id: number) =>
        ApiWithToken.delete(
          abstractOrganizationalSurvey("good_practices").uri(code),
          {
            params: {
              id: id,
            },
          }
        ),
      put: async (code: string | undefined, data: any, id?: number) =>
        ApiWithToken.put(
          abstractOrganizationalSurvey("good_practices").uri(code),
          data,
          {
            params: {
              id: id,
            },
          }
        ),
    },
  },
  measurements: {
    uri: "/api/client/measurement_processes/",
    get: async () => ApiWithToken.get(clientService.measurements.uri),
    getByCode: async (code: string | undefined) =>
      ApiWithToken.get(`/api/measurement_process/${code}/set_up/`),
    getMonitoreaData: async (code: string | undefined) =>
      ApiWithToken.get(`/api/measurement_process/${code}/progress/`),
    previewStaffSurvey: async (code: string | undefined) =>
      ApiWithToken.put(
        `/api/measurement_process/${code}/staff_survey/preview/`
      ),
    launchStaffSurvey: async (code: string | undefined) =>
      ApiWithToken.put(`/api/measurement_process/${code}/staff_survey/launch/`),
    distribution: {
      uri: (code: string | undefined) =>
        `/api/measurement_process/${code}/staff_survey/distribution/`,
      get: async (code: string | undefined) =>
        ApiWithToken.get(clientService.measurements.distribution.uri(code)),
      put: async (code: string | undefined, data: any) =>
        ApiWithToken.put(
          clientService.measurements.distribution.uri(code),
          data
        ),
    },
    personalization: {
      tags: {
        uri: (code: string | undefined) =>
          `/api/measurement_process/${code}/staff_survey/tags/`,
        get: async (code: string | undefined) =>
          ApiWithToken.get(
            clientService.measurements.personalization.tags.uri(code)
          ),
      },
      jobs: abstractPersonalization("jobs"),
      workplaces: abstractPersonalization("workplaces"),
      areas: abstractPersonalization("areas"),
      workShifts: abstractPersonalization("work_shifts"),
    },
    summary: {
      get: async (code: string | undefined) =>
        ApiWithToken.get(`/api/measurement_process/${code}/summary/`),
    },
    benchmark: {
      regional: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(
            `/api/measurement_process/${code}/benchmark/regional/`
          ),
      },
      country: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(
            `/api/measurement_process/${code}/benchmark/country/`
          ),
      },
    },
    results: {
      overview: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(
            `/api/measurement_process/${code}/results/overview/`
          ),
      },
      summary: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(
            `/api/measurement_process/${code}/results/summary/`,
            {
              responseType: "blob",
            }
          ),
      },
      sample: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(`/api/measurement_process/${code}/results/sample/`),
      },
      preventionSystem: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(
            `/api/measurement_process/${code}/results/prevention_system/`
          ),
      },
      prevalence: {
        frequency : {
          get: async (code: string | undefined) =>
            ApiWithToken.get(
              `/api/measurement_process/${code}/results/prevalence/frequency/`
            ),
        },
        situations: {
          get: async (code: string | undefined) =>
            ApiWithToken.get(
              `/api/measurement_process/${code}/results/prevalence/situations/`
            ),
        },
        actions: {
          get: async (code: string | undefined) =>
            ApiWithToken.get(
              `/api/measurement_process/${code}/results/prevalence/actions/`
            ),
        },
        costs: {
          get: async (code: string | undefined) =>
            ApiWithToken.get(
              `/api/measurement_process/${code}/results/prevalence/costs/`
            ),
        },
        stalker_profile: {
          get: async (code: string | undefined) =>
            ApiWithToken.get(
              `/api/measurement_process/${code}/results/prevalence/stalker_profile/`
            ),
        },
      },
      witnesses: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(`/api/measurement_process/${code}/results/witness/`),
      },
      leadership: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(
            `/api/measurement_process/${code}/results/leadership/`
          ),
      },
      organizationalEnvironment: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(
            `/api/measurement_process/${code}/results/organizational_environment/`
          ),
      },
      tolerance: {
        get: async (code: string | undefined) =>
          ApiWithToken.get(
            `/api/measurement_process/${code}/results/tolerance/`
          ),
      },
    },
    previousMetrics: {
      get: async (code: string | undefined) =>
        ApiWithToken.get(
          `/api/measurement_process/${code}/results/previous_metrics/`
        ),
    },
  },

  index: {
    uri: (code: string, type: string) =>
      `/api/measurement_process/${code}/index/${type}/`,
    score: (code: string) =>
      ApiWithToken.get(clientService.index.uri(code, "score")),
    history: (code: string) =>
      ApiWithToken.get(clientService.index.uri(code, "history")),
  },

  actionPlan: {
    uri: (code: string) => `/api/measurement_process/${code}/action_plan/`,
    planDetails: {
      get: async (code: string) =>
        ApiWithToken.get(`${clientService.actionPlan.uri(code)}details/`),
    },
    recommendation: {
      get: async (code: string, id: number) =>
        ApiWithToken.get(
          `${clientService.actionPlan.uri(code)}recommendation/${id}/`
        ),
      put: async (code: string, id: number, data: any) =>
        ApiWithToken.put(
          `${clientService.actionPlan.uri(code)}recommendation/${id}/`,
          data
        ),
    },
    plans: {
      get: async () => ApiWithToken.get("/api/client/action_plans/"),
      post: async (code: string) =>
        ApiWithToken.post(`${clientService.actionPlan.uri(code)}generate/`),
    },
    getExcel: async (code: string) =>
      ApiWithToken.get(`${clientService.actionPlan.uri(code)}generate_excel/`, {
        responseType: "blob",
      }),
  },
};
