import { TUserInfo } from "../../types";

export class User {
  user: TUserInfo;

  constructor(user: TUserInfo) {
    this.user = user;
  }

  // Getters y setters para business_information
  get clientId() {
    return this.user.business_information.id;
  }

  get contactName() {
    return this.user.business_information.contact_name;
  }

  get contactLastName() {
    return this.user.business_information.contact_lastname;
  }

  get contactEmail() {
    return this.user.business_information.contact_email;
  }

  get tradeName() {
    return this.user.business_information.trade_name;
  }

  get logo() {
    return this.user.business_information.logo;
  }

  get completedProfile() {
    return this.user.business_information.completed_profile;
  }

  set completedProfile(value: boolean) {
    this.user.business_information.completed_profile = value;
  }

  get termsAccepted() {
    return this.user.business_information.terms_accepted;
  }

  set termsAccepted(value: boolean) {
    this.user.business_information.terms_accepted = value;
  }

  // Getters y setters para resources_access
  get hasRegionalBenchmark() {
    return this.user.resources_access["Benchmark regional"];
  }

  set hasRegionalBenchmark(value: boolean) {
    this.user.resources_access["Benchmark regional"] = value;
  }

  get hasCountryBenchmark() {
    return this.user.resources_access["Benchmark país"];
  }

  set hasCountryBenchmark(value: boolean) {
    this.user.resources_access["Benchmark país"] = value;
  }

  get hasSectorBenchmark() {
    return this.user.resources_access["Benchmark sector"];
  }

  get hasMeasurementProcess() {
    return this.user.resources_access["Medición"];
  }

  set hasMeasurementProcess(value: boolean) {
    this.user.resources_access["Medición"] = value;
  }

  get hasActionPlan() {
    return this.user.resources_access["Plan de acción"];
  }

  get hasELSANetwork() {
    return this.user.resources_access["Red ELSA"];
  }

  set hasELSANetwork(value: boolean) {
    this.user.resources_access["Red ELSA"] = value;
  }

  get hasEvolutionaryResults() {
    return this.user.resources_access["Dashboard de resultados evolutivos"];
  }

  get hasAULA() {
    return this.user.resources_access["Aula Virtual"];
  }

  set hasAULA(value: boolean) {
    this.user.resources_access["Aula Virtual"] = value;
  }

  get hasIndex() {
    return this.user.resources_access["Índice ELSA"];
  }

  set hasIndex(value: boolean) {
    this.user.resources_access["Índice ELSA"] = value;
  }

  // Getters y setters para expired_resources
  get hasExpiredRegionalBenchmark() {
    return this.user.expired_resources["Benchmark regional"];
  }

  set hasExpiredRegionalBenchmark(value: boolean) {
    this.user.expired_resources["Benchmark regional"] = value;
  }

  get hasExpiredCountryBenchmark() {
    return this.user.expired_resources["Benchmark país"];
  }

  set hasExpiredCountryBenchmark(value: boolean) {
    this.user.expired_resources["Benchmark país"] = value;
  }

  get hasExpiredSectorBenchmark() {
    return this.user.expired_resources["Benchmark sector"];
  }

  get hasExpiredMeasurementProcess() {
    return this.user.expired_resources["Medición"];
  }

  set hasExpiredMeasurementProcess(value: boolean) {
    this.user.expired_resources["Medición"] = value;
  }

  get hasExpiredActionPlan() {
    return this.user.expired_resources["Plan de acción"];
  }

  get hasExpiredELSANetwork() {
    return this.user.expired_resources["Red ELSA"];
  }

  set hasExpiredELSANetwork(value: boolean) {
    this.user.expired_resources["Red ELSA"] = value;
  }

  get hasExpiredEvolutionaryResults() {
    return this.user.expired_resources["Dashboard de resultados evolutivos"];
  }

  get hasExpiredAULA() {
    return this.user.expired_resources["Aula Virtual"];
  }

  set hasExpiredAULA(value: boolean) {
    this.user.expired_resources["Aula Virtual"] = value;
  }

  get hasExpiredIndex() {
    return this.user.expired_resources["Índice ELSA"];
  }

  set hasExpiredIndex(value: boolean) {
    this.user.expired_resources["Índice ELSA"] = value;
  }
}
