import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  MantineThemeOverride,
} from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { useCookies } from "react-cookie";
import { ModalsProvider } from "@mantine/modals";

export const theme: MantineThemeOverride = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, 'Montserrat Classic', Montserrat, Kollektif",
  // fontFamily: "Helvetica",
  colors: {
    "genderlab-red": [
      "#FCE9E9",
      "#F6C1C1",
      "#F09999",
      "#EA7171",
      "#E44949",
      "#DE2121",
      "#B21A1A",
      "#851414",
      "#590D0D",
      "#2C0707",
    ],
  },
  primaryColor: "genderlab-red",
  components: {
    InputWrapper: {
      styles: (theme) => ({
        label: {
          fontWeight: 400,
          color: "#495057",
        },
      }),
    },
  },
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

const PRIMARY_THEME = "light";
const SECONDARY_THEME = "dark";

export function ThemeProvider({ children }: ThemeProviderProps) {
  const [cookies, setCookie] = useCookies(["colorScheme"]);

  const toggleColorScheme = (value?: ColorScheme) => {
    setCookie(
      "colorScheme",
      !cookies.colorScheme
        ? SECONDARY_THEME
        : cookies.colorScheme === PRIMARY_THEME
        ? SECONDARY_THEME
        : PRIMARY_THEME,
      { path: "/" }
    );
  };

  return (
    <ColorSchemeProvider
      colorScheme={cookies.colorScheme || PRIMARY_THEME}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ ...theme, colorScheme: cookies.colorScheme || PRIMARY_THEME }}
        withGlobalStyles
        withNormalizeCSS
      >
        <NotificationsProvider position="bottom-center">
          <ModalsProvider>{children}</ModalsProvider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
