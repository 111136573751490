import {
  Button,
  Flex,
  Loader,
  Modal,
  MultiSelect,
  Select,
  Tabs,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { BsDownload } from "react-icons/bs";

import {
  CustomLink,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
  NoTienesMedicionesComponent,
} from "../../components";
import { useAuth, useMetrics } from "../../context";
import { clientService } from "../../api";
import {
  TCountryBenchmark,
  TLeadership,
  TMedicion,
  TOrganizationalEnvironment,
  TPrevalence,
  TPreventionSystem,
  TRegionalBenchmark,
  TSample,
  TTolerance,
  TWitnesses,
  TPreviousMetrics,
} from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { Indice } from "./Indice";
import { MuestraResultados } from "./MuestraResultados";
import { SistemaDePrevencionResultados } from "./SistemaDePrevencionResultados";
import { ToleranciaResultados } from "./ToleranciaResultados";
import { PrevalenciaResultados } from "./PrevalenciaResultados";
import { TestigosResultados } from "./TestigosResultados";
import { ClimaOrganizacionalResultados } from "./ClimaOrganizacionalResultados";
import { LiderazgoResultados } from "./LiderazgoResultados";
import { contactURL } from "../../utils";

const useStyles = createStyles((theme) => ({
  title: {
    position: "sticky",
    zIndex: 2,
    backgroundColor: theme.colors.gray[0],
    top: 0,
  },
  filters: {
    position: "sticky",
    zIndex: 2,
    backgroundColor: theme.colors.gray[0],
    top: 70,
  },
  tabs: {
    position: "sticky",
    zIndex: 1,
    backgroundColor: theme.colors.gray[0],
    top: 140,
  },
}));

type TTab =
  | "sample"
  | "preventionSystem"
  | "tolerance"
  | "prevalence"
  | "witnesses"
  | "organizationalEnvironment"
  | "leadership";

export const ResultadosBase = () => {
  const { user } = useAuth();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { code, tab } = useParams();

  const [resultadosVencidosModalOpened, setResultadosVencidosModal] =
    useState(true);
  const [mps, setMps] = useState<TMedicion[] | null>(null);
  const [tabsData, setTabsData] = useState<{
    sample: TSample | null;
    preventionSystem: TPreventionSystem | null;
    tolerance: TTolerance | null;
    prevalence: TPrevalence | null;
    witnesses: TWitnesses | null;
    organizationalEnvironment: TOrganizationalEnvironment | null;
    leadership: TLeadership | null;
  }>({
    sample: null,
    preventionSystem: null,
    tolerance: null,
    prevalence: null,
    witnesses: null,
    organizationalEnvironment: null,
    leadership: null,
  });

  const [tempRb, setTempRb] = useState<TRegionalBenchmark | null>(null);
  const [tempCb, setTempCb] = useState<TCountryBenchmark | null>(null);
  const [tempPm, setTempPm] = useState<TPreviousMetrics | null>(null);

  const [filters, setFilters] = useState<string[]>([]);

  const [downloadingSummary, setDownloadingSummary] = useState(false);

  const { setRegionalBenchmark, setCountryBenchmark, setPreviousMetrics } =
    useMetrics();

  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const breadcrumbs = () => (
    <div className={classes.title}>
      <GenderLabBreadcrumbs breadcrumbs={[{ link: "", title: "Resultados" }]} />
    </div>
  );

  useEffect(() => {
    const getFilters = async () => {
      if (user) {
        try {
          setLoadingFilters(true);
          const tmpFilters: string[] = [];

          if (user.hasRegionalBenchmark) {
            const { data: benchmarkRegionalData } =
              await clientService.measurements.benchmark.regional.get(code);
            setRegionalBenchmark(benchmarkRegionalData);
            setTempRb(benchmarkRegionalData);
            tmpFilters.push("rb");
          }

          if (user.hasCountryBenchmark) {
            const { data: benchmarkCountryData } =
              await clientService.measurements.benchmark.country.get(code);
            if (!_.isEmpty(benchmarkCountryData.benchmarks)) {
              setCountryBenchmark(benchmarkCountryData);
              setTempCb(benchmarkCountryData);
              tmpFilters.push("cb");
            }
          }

          if (user.hasEvolutionaryResults) {
            const { data: previousMetricsData } =
              await clientService.measurements.previousMetrics.get(code);
            if (previousMetricsData.year !== null) {
              setPreviousMetrics(previousMetricsData);
              setTempPm(previousMetricsData);
              tmpFilters.push("pm");
            }
          }

          setFilters(tmpFilters);
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingFilters(false);
        }
      }
    };

    const getMps = async () => {
      try {
        const { data } = await clientService.measurements.get();
        let mpsToFilter: TMedicion[] = data;
        mpsToFilter = mpsToFilter.filter((mp) =>
          mp.staff_survey ? mp.staff_survey.status === "LAUNCHED" : false
        );
        mpsToFilter = mpsToFilter.reverse();
        setMps(mpsToFilter);
      } catch (error) {
        console.error(error);
      }
    };

    if (user) {
      getMps();
      if (user.hasMeasurementProcess) getFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, code]);

  useEffect(() => {
    if (!code && !tab && mps && mps.length > 0) {
      navigate(`/resultados/${mps[mps.length - 1].code}/index`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mps]);

  useEffect(() => {
    const getTabData = async (tab: TTab) => {
      try {
        const service = clientService.measurements.results[tab];
        if ("get" in service && typeof service.get === "function") {
          const response = await service.get(code);
          return response.data;
        } else {
          const subService = service as Record<string, any>;
          const data: Record<string, any> = {};
          for (const key in subService) {
            if (
              subService[key]?.get &&
              typeof subService[key].get === "function"
            ) {
              const response = await subService[key].get(code);
              data[key] = response.data;
            }
          }
          if (Object.keys(data).length > 0) {
            return data;
          } else {
            return { error: "No data" };
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (code && tab && tab !== "index") {
      const ttab: TTab = tab as TTab;
      getTabData(ttab).then((data) => {
        setTabsData((prev) => ({ ...prev, [ttab]: data }));
      });
    }
  }, [code, tab]);

  const downloadSummary = async () => {
    if (code) {
      try {
        setDownloadingSummary(true);
        const response = await clientService.measurements.results.summary.get(
          code
        );

        const blob = new Blob([response.data], { type: "application/pdf" });

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "resumen-resultados.pdf");

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error al descargar el PDF", error);
      } finally {
        setDownloadingSummary(false);
      }
    }
  };

  useEffect(
    () => {
      if (user?.hasMeasurementProcess) {
        if (filters.includes("rb")) {
          setRegionalBenchmark(tempRb);
        } else {
          setRegionalBenchmark(null);
        }
        if (filters.includes("cb")) {
          setCountryBenchmark(tempCb);
        } else {
          setCountryBenchmark(null);
        }
        if (filters.includes("pm")) {
          setPreviousMetrics(tempPm);
        } else {
          setPreviousMetrics(null);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, user]
  );

  const loader = () => (
    <GenderLabAppShell>
      {breadcrumbs()}
      <GenderLabLoader />
    </GenderLabAppShell>
  );

  if (!user) {
    return loader();
  }

  if (mps === null) {
    return loader();
  } else {
    if (mps.length === 0) {
      if (user.hasMeasurementProcess) {
        return (
          <GenderLabAppShell>
            {breadcrumbs()}
            <Text color="dimmed" fz="lg">
              Pronto podrás ver aquí los resultados de tus mediciones ELSA que
              hayan sido terminadas.
            </Text>
          </GenderLabAppShell>
        );
      } else {
        return (
          <GenderLabAppShell>
            {breadcrumbs()}
            <NoTienesMedicionesComponent />
          </GenderLabAppShell>
        );
      }
    } else {
      if (code) {
        return (
          <GenderLabAppShell fullScreen>
            {breadcrumbs()}
            <div>
              <div className={classes.filters}>
                <Flex gap="sm" pb="md" align="flex-end">
                  <Select
                    label="Medición"
                    value={code}
                    onChange={(value: string) => {
                      navigate(`/resultados/${value}/${tab}`);
                    }}
                    data={mps.map((mp) => ({
                      value: mp.code,
                      label: mp.title,
                    }))}
                  />
                  <MultiSelect
                    label="Filtros"
                    value={filters}
                    onChange={setFilters}
                    miw={150}
                    data={[
                      {
                        value: "rb",
                        label: "Benchmark Regional",
                        disabled: !user.hasRegionalBenchmark,
                      },
                      {
                        value: "cb",
                        label: "Benchmark País",
                        disabled: !user.hasCountryBenchmark,
                      },
                      {
                        value: "pm",
                        label: "Medición anterior",
                        disabled: !user.hasEvolutionaryResults,
                      },
                    ]}
                    disabled={loadingFilters || !user.hasMeasurementProcess}
                    placeholder="Selecciona los filtros"
                  />
                  <Button
                    color="blue"
                    onClick={downloadSummary}
                    size="xs"
                    disabled={downloadingSummary}
                  >
                    <Flex gap="xs" align="center">
                      {downloadingSummary ? (
                        <Loader size="xs" />
                      ) : (
                        <BsDownload />
                      )}{" "}
                      Descargar resumen
                    </Flex>
                  </Button>
                </Flex>
              </div>
              <Tabs
                value={tab}
                onTabChange={(value) =>
                  navigate(`/resultados/${code}/${value}`)
                }
                orientation="horizontal"
              >
                <div className={classes.tabs}>
                  <Tabs.List grow>
                    <Tabs.Tab value="index">Índice</Tabs.Tab>
                    <Tabs.Tab value="sample">Muestra</Tabs.Tab>
                    <Tabs.Tab value="preventionSystem">
                      Sistema de prevención
                    </Tabs.Tab>
                    <Tabs.Tab value="tolerance">Tolerancia</Tabs.Tab>
                    <Tabs.Tab value="prevalence">Prevalencia</Tabs.Tab>
                    <Tabs.Tab value="witnesses">Testigos</Tabs.Tab>
                    <Tabs.Tab value="organizationalEnvironment">
                      Clima organizacional
                    </Tabs.Tab>
                    <Tabs.Tab value="leadership">Liderazgo</Tabs.Tab>
                  </Tabs.List>
                </div>

                <Tabs.Panel value="index" pt="md">
                  <Indice code={code!!} />
                </Tabs.Panel>
                <Tabs.Panel value="sample" pt="md">
                  <MuestraResultados sample={tabsData.sample} />
                </Tabs.Panel>
                <Tabs.Panel value="preventionSystem" pt="md">
                  <SistemaDePrevencionResultados
                    preventionSystem={tabsData.preventionSystem}
                  />
                </Tabs.Panel>
                <Tabs.Panel value="tolerance" pt="md">
                  <ToleranciaResultados tolerance={tabsData.tolerance} />
                </Tabs.Panel>
                <Tabs.Panel value="prevalence" pt="md">
                  <PrevalenciaResultados prevalence={tabsData.prevalence} />
                </Tabs.Panel>
                <Tabs.Panel value="witnesses" pt="md">
                  <TestigosResultados witnesses={tabsData.witnesses} />
                </Tabs.Panel>
                <Tabs.Panel value="organizationalEnvironment" pt="md">
                  <ClimaOrganizacionalResultados
                    organizationalEnvironment={
                      tabsData.organizationalEnvironment
                    }
                  />
                </Tabs.Panel>
                <Tabs.Panel value="leadership" pt="md">
                  <LiderazgoResultados leadership={tabsData.leadership} />
                </Tabs.Panel>
              </Tabs>
            </div>
            {!user.hasMeasurementProcess && (
              <Modal
                title={<Title order={5}>Suscripción vencida</Title>}
                opened={resultadosVencidosModalOpened}
                onClose={() => setResultadosVencidosModal(false)}
              >
                Estás viendo los resultados de tu medición previa.
                Lamentablemente no cuentas con una suscripción ELSA activa, si
                quieres renovar y recuperar benchmarks, comparaciones y acceso a
                recursos,{" "}
                <CustomLink to={contactURL} target="_blank" fontSize="16px">
                  contáctanos
                </CustomLink>
                .
              </Modal>
            )}
          </GenderLabAppShell>
        );
      } else {
        return loader();
      }
    }
  }
};
