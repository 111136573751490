import {
  Title,
  Flex,
  Grid,
  Text,
  Button,
  ScrollArea,
  Center,
} from "@mantine/core";
import { openModal } from "@mantine/modals";
import { useEffect, useState } from "react";

import { TPrevalence } from "../../types";
import {
  BarChart,
  BarrerasGeneralesCard,
  Heatmap,
  PieChart,
  ResultadosCard,
  SectionTitle,
  GenderLabLoader,
  PrevalenciaTooltip,
} from "../../components";
import {
  colorAmarillo1,
  colorAzul1,
  colorCeleste1,
  colorRojoClaro1,
  parsePercentage,
} from "../../utils";
import {
  PrevalenceMetricsChart,
  SelectCortesData,
  TitleCard,
} from "../../components/Resultados";

type Props = {
  prevalence: TPrevalence | null;
};

export const PrevalenciaResultados = ({ prevalence }: Props) => {
  const [prevalenceCategories, setPrevalenceCategories] = useState<
    string[] | null
  >(null);
  const [prevalenceData, setPrevalenceData] = useState<number[] | null>(null);

  const [totalManifestaciones, setTotalManifestaciones] = useState<{
    name: string;
    data: number[];
  } | null>(null);

  const [heatmapData, setHeatmapData] = useState<{
    [key: string]: { [key: string]: number };
  } | null>(null);

  const situationHeatmapCategories = [
    "M1",
    "M2",
    "M3",
    "M4",
    "M5",
    "M6",
    "M7",
    "M8",
    "M9",
    "M10",
    "M11",
    "M12",
  ];

  const minimumBarChartLength = 0.15;

  const responsiveBarStylesPrevalencia = [
    {
      breakpoint: 768,
      options: {
        chart: {
          width: 300,
        },
      },
    },
  ];

  useEffect(() => {
    if (prevalence) {
      setTotalManifestaciones({
        name: "Total",
        data: Object.values(prevalence.situations.situations).map((value) =>
          parsePercentage(value)
        ),
      });
      setHeatmapData(prevalence.situations.gender);
      setPrevalenceCategories([
        "Total",
        ...Object.keys(prevalence.frequency.prevalence_by_gender),
      ]);
      setPrevalenceData([
        prevalence.frequency.technical_harassment_prevalence,
        ...Object.values(prevalence.frequency.prevalence_by_gender),
      ]);
    }
  }, [prevalence]);

  const onClickOtrasReacciones = () => {
    openModal({
      title: <Title order={4}>Otras barreras para la denuncia</Title>,
      children: (
        <ScrollArea style={{ maxHeight: 500 }}>
          <ul>
            {prevalence?.actions.non_reporting_reasons.others.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </ScrollArea>
      ),
      size: "xl",
    });
  };

  const onSelectChangeHeatmap = (value: string | null) => {
    if (prevalence) {
      switch (value) {
        case "genero":
          setHeatmapData(prevalence.situations.gender);
          break;
        case "edad":
          setHeatmapData(prevalence.situations.age);
          break;
        case "orientacion-sexual":
          setHeatmapData(prevalence.situations.sexual_orientation);
          break;
        case "grupo-etnico-racial":
          setHeatmapData(prevalence.situations.ethnicity);
          break;
        case "puesto-de-trabajo":
          setHeatmapData(prevalence.situations.job);
          break;
        case "area":
          setHeatmapData(prevalence.situations.area);
          break;
        case "centro-de-trabajo":
          setHeatmapData(prevalence.situations.workplace);
          break;
        case "turno":
          setHeatmapData(prevalence.situations.work_shift);
          break;
      }
    }
  };

  const onSelectChangePrevalence = (value: string | null) => {
    if (prevalence) {
      switch (value) {
        case "genero":
          setPrevalenceCategories([
            "Total",
            ...Object.keys(prevalence.frequency.prevalence_by_gender),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_gender),
          ]);
          break;
        case "edad":
          setPrevalenceCategories([
            "Total",
            ...Object.keys(prevalence.frequency.prevalence_by_age),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_age),
          ]);
          break;
        case "orientacion-sexual":
          setPrevalenceCategories([
            "Total",
            ...Object.keys(
              prevalence.frequency.prevalence_by_sexual_orientation
            ),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(
              prevalence.frequency.prevalence_by_sexual_orientation
            ),
          ]);
          break;
        case "grupo-etnico-racial":
          setPrevalenceCategories([
            "Total",
            ...Object.keys(prevalence.frequency.prevalence_by_ethnicity),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_ethnicity),
          ]);
          break;
        case "puesto-de-trabajo":
          setPrevalenceCategories([
            "Total",
            ...Object.keys(prevalence.frequency.prevalence_by_job),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_job),
          ]);
          break;
        case "area":
          setPrevalenceCategories([
            "Total",
            ...Object.keys(prevalence.frequency.prevalence_by_area),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_area),
          ]);
          break;
        case "centro-de-trabajo":
          setPrevalenceCategories([
            "Total",
            ...Object.keys(prevalence.frequency.prevalence_by_workplace),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_workplace),
          ]);
          break;
        case "turno":
          setPrevalenceCategories([
            "Total",
            ...Object.keys(prevalence.frequency.prevalence_by_work_shift),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_work_shift),
          ]);
          break;
      }
    }
  };

  if (!prevalence || !totalManifestaciones || !heatmapData) {
    return <GenderLabLoader />;
  } else
    return (
      <Flex direction="column" gap="md">
        <SectionTitle
          title="Prevalencia"
          description="Nivel de incidencia, reacciones e impacto del hostigamiento o acoso sexual laboral en tu organización."
        />
        <Flex direction={{ base: "column", md: "row" }} gap="md">
          <ResultadosCard
            title="Prevalencia"
            description="Porcentaje de personas que experimentó acoso u hostigamiento sexual en el último año."
            titleChildren={<PrevalenciaTooltip />}
          >
            <Flex justify="center">
              <PrevalenceMetricsChart
                currentTechHarrassment={
                  prevalence.frequency.technical_harassment_prevalence
                }
                currentDeclaredHarrassment={
                  prevalence.frequency.declared_harassment_prevalence
                }
              />
            </Flex>
          </ResultadosCard>
          <ResultadosCard
            title="Frecuencia"
            description="Frecuencia con la que se reportó que estas situaciones se presentaban."
          >
            <PieChart
              height={400}
              labels={prevalence.frequency.frequency_technical_harassment.map(
                (obj) => obj.description
              )}
              series={prevalence.frequency.frequency_technical_harassment.map(
                (obj) => obj.count
              )}
            />
          </ResultadosCard>
        </Flex>
        {/* <ResultadosCard
          title="Prevalencia según perfil personal"
          description="Nivel de incidencia de acoso sexual por cada grupo poblacional."
        >
          <Grid>
            <Grid.Col
              span={12}
              md={
                prevalence.frequency.prevalence_by_sexual_orientation ? 6 : 12
              }
            >
              <BarChart
                height={300}
                width={
                  !prevalence.frequency.prevalence_by_sexual_orientation &&
                  !prevalence.frequency.prevalence_by_age
                    ? 700
                    : 400
                }
                responsive={responsiveBarStylesPrevalencia}
                title="Prevalencia según género"
                categories={[
                  "Total",
                  ...Object.keys(prevalence.frequency.prevalence_by_gender),
                ]}
                seriesData={[
                  [
                    prevalence.frequency.technical_harassment_prevalence,
                    ...Object.values(prevalence.frequency.prevalence_by_gender),
                  ],
                ]}
                seriesNames={["Prevalencia (%)"]}
                minimumValue={minimumBarChartLength}
                max={100}
              />
            </Grid.Col>
            {prevalence.frequency.prevalence_by_sexual_orientation && (
              <Grid.Col span={12} md={6}>
                <BarChart
                  height={300}
                  width={400}
                  responsive={responsiveBarStylesPrevalencia}
                  title="Prevalencia en comunidad LGBTIQ+"
                  categories={[
                    "Total",
                    ...Object.keys(
                      prevalence.frequency.prevalence_by_sexual_orientation
                    ),
                  ]}
                  seriesData={[
                    [
                      prevalence.frequency.technical_harassment_prevalence,
                      ...Object.values(
                        prevalence.frequency.prevalence_by_sexual_orientation
                      ),
                    ],
                  ]}
                  seriesNames={["Prevalencia (%)"]}
                  minimumValue={minimumBarChartLength}
                  max={100}
                />
              </Grid.Col>
            )}
            {prevalence.frequency.prevalence_by_age && (
              <Grid.Col span={12} md={6}>
                <BarChart
                  height={300}
                  width={400}
                  responsive={responsiveBarStylesPrevalencia}
                  title="Prevalencia según edad"
                  categories={[
                    "Total",
                    ...Object.keys(prevalence.frequency.prevalence_by_age),
                  ]}
                  seriesData={[
                    [
                      prevalence.frequency.technical_harassment_prevalence,
                      ...Object.values(prevalence.frequency.prevalence_by_age),
                    ],
                  ]}
                  seriesNames={["Prevalencia (%)"]}
                  minimumValue={minimumBarChartLength}
                  max={100}
                />
              </Grid.Col>
            )}
            {prevalence.frequency.prevalence_by_ethnicity && (
              <Grid.Col span={12} md={6}>
                <BarChart
                  height={300}
                  width={400}
                  responsive={responsiveBarStylesPrevalencia}
                  title="Prevalencia según autoidentificación étnico-racial"
                  categories={[
                    "Total",
                    ...Object.keys(
                      prevalence.frequency.prevalence_by_ethnicity
                    ),
                  ]}
                  seriesData={[
                    [
                      prevalence.frequency.technical_harassment_prevalence,
                      ...Object.values(
                        prevalence.frequency.prevalence_by_ethnicity
                      ),
                    ],
                  ]}
                  seriesNames={["Prevalencia (%)"]}
                  minimumValue={minimumBarChartLength}
                  max={100}
                />
              </Grid.Col>
            )}
          </Grid>
          <Flex
            direction={{ base: "column", xl: "row" }}
            gap="sm"
            align="center"
            justify="space-evenly"
          ></Flex>
        </ResultadosCard>
        <ResultadosCard
          title="Prevalencia según perfil laboral"
          description="Nivel de incidencia de acoso sexual por cada grupo poblacional."
        >
          <Grid>
            <Grid.Col
              span={12}
              md={prevalence.frequency.prevalence_by_area ? 6 : 12}
            >
              <BarChart
                height={300}
                width={400}
                responsive={responsiveBarStylesPrevalencia}
                title="Prevalencia según puesto de trabajo"
                categories={[
                  "Total",
                  ...Object.keys(prevalence.frequency.prevalence_by_job),
                ]}
                seriesData={[
                  [
                    prevalence.frequency.technical_harassment_prevalence,
                    ...Object.values(prevalence.frequency.prevalence_by_job),
                  ],
                ]}
                seriesNames={["Prevalencia (%)"]}
                minimumValue={minimumBarChartLength}
                max={100}
              />
            </Grid.Col>
            {prevalence.frequency.prevalence_by_area && (
              <Grid.Col span={12} md={6}>
                <BarChart
                  height={300}
                  width={400}
                  responsive={responsiveBarStylesPrevalencia}
                  title="Prevalencia según área"
                  categories={[
                    "Total",
                    ...Object.keys(prevalence.frequency.prevalence_by_area),
                  ]}
                  seriesData={[
                    [
                      prevalence.frequency.technical_harassment_prevalence,
                      ...Object.values(prevalence.frequency.prevalence_by_area),
                    ],
                  ]}
                  seriesNames={["Prevalencia (%)"]}
                  minimumValue={minimumBarChartLength}
                  max={100}
                />
              </Grid.Col>
            )}
            {prevalence.frequency.prevalence_by_workplace && (
              <Grid.Col
                span={12}
                md={prevalence.frequency.prevalence_by_work_shift ? 6 : 12}
              >
                <BarChart
                  height={300}
                  width={400}
                  responsive={responsiveBarStylesPrevalencia}
                  title="Prevalencia según centro de trabajo"
                  categories={[
                    "Total",
                    ...Object.keys(
                      prevalence.frequency.prevalence_by_workplace
                    ),
                  ]}
                  seriesData={[
                    [
                      prevalence.frequency.technical_harassment_prevalence,
                      ...Object.values(
                        prevalence.frequency.prevalence_by_workplace
                      ),
                    ],
                  ]}
                  seriesNames={["Prevalencia (%)"]}
                  minimumValue={minimumBarChartLength}
                  max={100}
                />
              </Grid.Col>
            )}
            {prevalence.frequency.prevalence_by_work_shift && (
              <Grid.Col span={12} md={6}>
                <BarChart
                  height={300}
                  width={400}
                  responsive={responsiveBarStylesPrevalencia}
                  title="Prevalencia según turno"
                  categories={[
                    "Total",
                    ...Object.keys(
                      prevalence.frequency.prevalence_by_work_shift
                    ),
                  ]}
                  seriesData={[
                    [
                      prevalence.frequency.technical_harassment_prevalence,
                      ...Object.values(
                        prevalence.frequency.prevalence_by_work_shift
                      ),
                    ],
                  ]}
                  seriesNames={["Prevalencia (%)"]}
                  minimumValue={minimumBarChartLength}
                  max={100}
                />
              </Grid.Col>
            )}
          </Grid>
        </ResultadosCard> */}
        <ResultadosCard
          title="Prevalencia en la organización por perfil"
          description="Nivel de incidencia de acoso sexual laboral por cada grupo poblacional."
          titleChildren={
            <SelectCortesData
              onSelectChange={onSelectChangePrevalence}
              genderDisabled={!prevalence.situations?.gender}
              ageDisabled={!prevalence.situations?.age}
              sexualOrientationDisabled={
                !prevalence.situations?.sexual_orientation
              }
              ethnicityDisabled={!prevalence.situations?.ethnicity}
              jobDisabled={!prevalence.situations?.job}
              areaDisabled={!prevalence.situations?.area}
              workplaceDisabled={!prevalence.situations?.workplace}
              workShiftDisabled={!prevalence.situations?.work_shift}
            />
          }
        >
          <BarChart
            height={500}
            width={600}
            max={
              prevalenceData
                ? Math.min(
                    Math.ceil((Math.max(...prevalenceData) * 100) / 5) * 5,
                    100
                  )
                : 100
            }
            responsive={responsiveBarStylesPrevalencia}
            categories={prevalenceCategories || []}
            horizontal={true}
            seriesData={prevalenceData ? [prevalenceData] : []}
          />
        </ResultadosCard>
        <ResultadosCard
          title="Principales manifestaciones que impactan en la organización"
          description="Situaciones identificadas por las personas que han vivido hostigamiento o acoso sexual laboral."
          titleChildren={
            <SelectCortesData
              onSelectChange={onSelectChangeHeatmap}
              genderDisabled={!prevalence.situations?.gender}
              ageDisabled={!prevalence.situations?.age}
              sexualOrientationDisabled={
                !prevalence.situations?.sexual_orientation
              }
              ethnicityDisabled={!prevalence.situations?.ethnicity}
              jobDisabled={!prevalence.situations?.job}
              areaDisabled={!prevalence.situations?.area}
              workplaceDisabled={!prevalence.situations?.workplace}
              workShiftDisabled={!prevalence.situations?.work_shift}
            />
          }
        >
          <Heatmap
            data={[
              totalManifestaciones,
              ...Object.keys(heatmapData).map((name) => ({
                name: name,
                data: Object.values(heatmapData[name]).map((value) =>
                  parsePercentage(value)
                ),
              })),
            ]}
            categories={situationHeatmapCategories}
          />
          <Grid gutter="md">
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M1: Comentarios o bromas de contenido sexual o sexista.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M2: Comentarios o bromas sobre de su orientación sexual o
                identidad de género.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M3: Comentarios sobre su apariencia física o cuerpo que le
                generaron incomodidad, incluyendo también gestos, silbidos o
                sonidos.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M4: Invitaciones reiteradas a salir a pesar de que no ofreció
                respuesta o no dio respuesta positiva.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M5: Contactos reiterados a través de mensajes electrónicos,
                chats, WhatsApp, Facebook, entre otros, con fines no laborales
                que le incomodaron.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M6: Solicitudes o envíos sin consentimiento de fotos o videos de
                material sexual explícito o de connotación sexual.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M7: Acercamientos físicos o tocamientos (rozar, sobar, masajear,
                etc.) sin necesidad en alguna parte del cuerpo como hombros,
                espalda baja, rodilla u otras partes.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M8: Tocamientos y/o rozamientos en zonas privadas del cuerpo,
                como senos, genitales, etc.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M9: Miradas persistentes con connotación sexual.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M10: Insinuaciones o invitaciones a salir de un jefe/a a pesar
                de que de alguna manera le expresó su incomodidad.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M11: Insinuaciones o propuestas de mejoras en su situación
                laboral a cambio de aceptar invitaciones, acercamientos o
                encuentros sexuales.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M12: Amenazas o insinuaciones de despido, retiro de beneficios o
                tomar alguna represalia por no aceptar invitaciones,
                acercamientos, o encuentros sexuales.
              </Text>
            </Grid.Col>
          </Grid>
        </ResultadosCard>
        <TitleCard
          title="Métricas asociadas a Acoso u Hostigamiento Sexual Laboral Declarado"
          description="Calculadas en base a las personas que reconocieron haber vivido acoso sexual laboral y las acciones que tomaron, costos asociados, barreras para la denuncia y perfil de las personas que realizaron estas conductas."
        />
        <Flex direction={{ base: "column", md: "row" }} gap="md">
          <ResultadosCard
            title="Reacciones frente al hostigamiento o acoso sexual laboral"
            description="Reacciones que tuvieron las personas que reconocen expresamente haber vivido acoso sexual."
          >
            <Flex align="center" direction="column">
              <BarChart
                height={500}
                width={650}
                responsive={responsiveBarStylesPrevalencia}
                categories={prevalence.actions.taken_actions_by_victims.map(
                  (action) =>
                    action[0]
                      .split(" ")
                      .reduce((acc: string[], word, index, words) => {
                        if (index % 4 === 0)
                          acc.push(words.slice(index, index + 4).join(" "));
                        return acc;
                      }, [])
                )}
                seriesNames={["Porcentaje (%)"]}
                seriesData={[
                  prevalence.actions.taken_actions_by_victims.map(
                    (action) => action[1]
                  ),
                ]}
                max={100}
              />
            </Flex>
          </ResultadosCard>
          <ResultadosCard
            title="Costos asociados al hostigamiento o acoso sexual laboral"
            description="Afectaciones en el bienestar y la productividad de quienes han sufrido acoso sexual laboral."
          >
            <Flex direction="column" gap="md" align="center">
              <BarChart
                height={500}
                width={650}
                responsive={responsiveBarStylesPrevalencia}
                colors={[colorAmarillo1, colorCeleste1]}
                categories={[
                  "Sintió estrés",
                  "Sintió ansiedad",
                  ["Experimentó dificultades", " para concentrarse"],
                  "Redujo su productividad",
                  "Faltó al trabajo",
                ]}
                max={100}
                seriesNames={["Hostigamiento o acoso sexual laboral declarado"]}
                seriesData={[
                  [
                    prevalence.costs.stress.declared,
                    prevalence.costs.anxiety.declared,
                    prevalence.costs.concentration_problems.declared,
                    prevalence.costs.decreased_productivity.declared,
                    prevalence.costs.absenteeism.declared,
                  ],
                ]}
              />
            </Flex>
          </ResultadosCard>
        </Flex>
        <ResultadosCard
          title="Barreras para la denuncia"
          description="Razones por las que las personas que reconocen expresamente haber vivido acoso sexual no denunciaron lo sucedido."
        >
          <Flex
            direction={{ base: "column", lg: "row" }}
            gap="md"
            justify="center"
            align="center"
          >
            <Flex
              direction={{ base: "column", lg: "row" }}
              gap="lg"
              align="center"
            >
              <Flex direction="column" gap="xs">
                <BarrerasGeneralesCard
                  colors={["#f9d6d6", colorRojoClaro1, colorRojoClaro1]}
                  title="Miedo por factores endógenos a la organización"
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .fear
                  }
                  subtitles={[
                    "Tenía vergüenza",
                    "No quería ser vista como una persona problemática en el trabajo",
                    "Tenía miedo de perder mi trabajo o de sufrir algún otro tipo de represalia",
                  ]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "Tenía vergüenza."
                    ],
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No quería ser vista como una persona problemática."
                    ],
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "Tenía miedo de peder su trabajo o recibir represalias."
                    ],
                  ]}
                />
                <BarrerasGeneralesCard
                  colors={["#cde9e7", "#7cc5c1", "#109089"]}
                  title="Falta de información"
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .misinformation
                  }
                  subtitles={[
                    "En ese momento no estaba seguro/a de si era un caso de hostigamiento o acoso sexual laboral",
                    "No sabía o no existe un canal de denuncia",
                  ]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No estaba seguro/a de que fuera un caso de acoso sexual laboral."
                    ],
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No sabía o no existe un canal de denuncia."
                    ],
                  ]}
                />
              </Flex>
              <Flex direction="column" gap="xs">
                <BarrerasGeneralesCard
                  colors={["#ddedf8", "#add4f0", "#4fa0db"]}
                  title="Factores exógenos a la organización"
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .others_reasons
                  }
                  subtitles={["Tenía miedo de tener problemas con mi pareja"]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "Tenía miedo de tener problemas con mi pareja."
                    ],
                  ]}
                />
                <BarrerasGeneralesCard
                  colors={["#e7e7e9", "#c2c3c8", "#626161"]}
                  title="Desconfianza en la organización"
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .distrust
                  }
                  subtitles={["Pienso que no sirve de nada denunciar"]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "Piensa que no sirve denunciar."
                    ],
                  ]}
                />
                <BarrerasGeneralesCard
                  colors={["#dccfe7", "#a482be", "#500f82"]}
                  title="Consideraciones con acosador/a"
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .consideration_with_stalker
                  }
                  subtitles={[
                    "Nada, me pareció que no fue de mala intención",
                    "No quería ocasionarle problemas a la persona",
                  ]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No les pareció de mala intención."
                    ],
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No quería ocasionarle problemas a la otra persona."
                    ],
                  ]}
                />
              </Flex>
            </Flex>
          </Flex>
          <Center mt="md">
            <Button variant="light" onClick={onClickOtrasReacciones} size="lg">
              Ver otras barreras
            </Button>
          </Center>
        </ResultadosCard>
        <ResultadosCard
          title="¿Quiénes realizan estas conductas?"
          description="Perfil de las personas que realizaron las situaciones de acoso sexual según las personas que reconocen expresamente haberlas vivido."
        >
          <Flex
            direction={{ base: "column", xl: "row" }}
            gap="md"
            justify="space-evenly"
            align="center"
          >
            <BarChart
              width={600}
              colors={[colorAzul1]}
              categories={["Hombres", "Mujeres"]}
              responsive={responsiveBarStylesPrevalencia}
              title="Género de la persona que hostiga o acosa"
              seriesData={[
                [
                  prevalence.stalker_profile.gender_of_stalker.men,
                  prevalence.stalker_profile.gender_of_stalker.women,
                ],
              ]}
            />
            <BarChart
              width={600}
              categories={[
                "Colega o compañero/a",
                "Jefe/a",
                "Subordinado/a",
                "Trabajador/a de otra organización",
                "Cliente o usuario",
              ]}
              colors={[colorAzul1]}
              responsive={responsiveBarStylesPrevalencia}
              title="¿Qué relación tenía con la persona que acosaba?"
              seriesData={[
                [
                  prevalence.stalker_profile.relationship_with_stalker.coworker,
                  prevalence.stalker_profile.relationship_with_stalker.boss,
                  prevalence.stalker_profile.relationship_with_stalker
                    .subordinate,
                  prevalence.stalker_profile.relationship_with_stalker.supplier,
                  prevalence.stalker_profile.relationship_with_stalker.client,
                ],
              ]}
            />
          </Flex>
        </ResultadosCard>
      </Flex>
    );
};
