import {
  Button,
  Popover,
  createStyles,
  Text,
  Title,
  Flex,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AiOutlineInfoCircle } from "react-icons/ai";

const useStyles = createStyles((theme) => ({
  infoButton: {
    "&:hover": {
      backgroundColor: "white",
    },
    margin: 0,
    padding: 0,
  },
}));

type Props = {
  width?: number;
  position?: "top" | "bottom" | "left" | "right";
  size?: "sm" | "md" | "lg" | "xl";
};

export const PrevalenciaTooltip = ({
  width = 400,
  position = "bottom",
  size = "sm",
}: Props) => {
  const { classes } = useStyles();
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover width={width} position={position} shadow="md" opened={opened}>
      <Popover.Target>
        <Button
          onMouseEnter={open}
          onMouseLeave={close}
          compact
          variant="subtle"
          size={size}
          className={classes.infoButton}
        >
          <AiOutlineInfoCircle />
        </Button>
      </Popover.Target>
      <Popover.Dropdown
        sx={{
          pointerEvents: "none",
        }}
      >
        <Flex direction="column" gap="xl">
          <Flex direction="column" gap="sm" sx={{ marginBottom: 15 }}>
            <Title order={5}>Acoso Sexual Laboral Técnico</Title>
            <Text align="justify">
              Porcentaje de personas que han pasado por al menos una situación
              de acoso sexual laboral en los últimos 12 meses;
              independientemente de si reconocieron o no la situación como acoso
              sexual laboral. Esta cifra refleja de mejor manera lo que
              realmente ocurre en las organizaciones.
            </Text>
          </Flex>
          <Flex direction="column" gap="sm">
            <Title order={5}>Acoso Sexual Laboral Declarado</Title>
            <Text align="justify">
              Porcentaje de personas que reconocen haber pasado al menos una
              situación de acoso sexual laboral en los últimos 12 meses. Esta
              cifra tiende a ser menor que la de acoso sexual laboral técnico,
              ya que las personas tienden a no reconocer la situación como acoso
              sexual laboral a pesar de haber pasado por ella.
            </Text>
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
