import { TPreventionSystem } from "../../types";
import { Center, Flex, List, Tabs, createStyles } from "@mantine/core";
import { useEffect, useState } from "react";

import {
  BarChart,
  FilaChecklistPrevencion,
  ResultadosCard,
  SectionTitle,
  GenderLabLoader,
} from "../../components";
import {
  colorAmarillo1,
  colorCeleste1,
  colorVerdeAgua1,
  colorRojoClaro1,
  colorAzul1,
} from "../../utils";
import { useAuth, useMetrics } from "../../context";
import {
  SelectCortesData,
  StackedBarChartConocimiento,
} from "../../components/Resultados";

const useStyles = createStyles(() => ({
  container: {
    maxWidth: 700,
  },

  conocimientoContainer: {
    maxWidth: 1400,
    margin: "0 auto",
  },
}));

type Props = {
  preventionSystem: TPreventionSystem | null;
};

type SeriesData = {
  name: string;
  data: number[];
};

export const SistemaDePrevencionResultados = ({ preventionSystem }: Props) => {
  const { user } = useAuth();
  const { classes } = useStyles();
  const { regionalBenchmark, countryBenchmark, previousMetrics } = useMetrics();

  const [identifiedChannels, setIdentifiedChannels] = useState<{
    [key: string]: number;
  }>({});

  const [knowledgeCategories, setKnowledgeCategories] = useState<
    string[] | null
  >(null);
  const [knowledgeData, setKnowledgeData] = useState<{
    [key: string]: SeriesData[];
  } | null>(null);

  const parseCategories = (knowledge: {
    [key: string]: { [key: string]: number };
  }) => {
    return Object.keys(knowledge);
  };

  const parseData = (
    knowledge: { [key: string]: { [key: string]: number } },
    knowledge_type: string
  ): SeriesData[] => {
    const seriesName: { [key: string]: string[] } = {
      policy: [
        "Sí, la he revisado.",
        "Sé que existe pero no la he revisado.",
        "No.",
      ],
      training: ["Sí, más de una vez.", "Sí, una vez.", "No, nunca."],
      channel: [
        "Sí, y sé cómo acceder a él.",
        "Sé que existe pero no sé cómo acceder a él.",
        "No.",
      ],
      procedure: ["Sí, lo he revisado.", "No."],
    };
    const selectedSeriesNames = seriesName[knowledge_type];
    const seriesData: SeriesData[] = selectedSeriesNames.map((name) => ({
      name,
      data: [],
    }));

    Object.values(knowledge).forEach((categoryData) => {
      selectedSeriesNames.forEach((seriesName, index) => {
        const value = categoryData[seriesName] || 0; // Rellenar con 0 si no existe el valor
        seriesData[index].data.push(value);
      });
    });
    console.log(seriesData);
    return seriesData;
  };

  useEffect(() => {
    if (preventionSystem) {
      const identifiedChannels = preventionSystem.identified_channels;
      const sortedIdentifiedChannels: any = Object.entries(identifiedChannels)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      setIdentifiedChannels(sortedIdentifiedChannels);
      setKnowledgeCategories(
        parseCategories(preventionSystem.policy_knowledge.gender)
      );
      setKnowledgeData({
        policy: parseData(preventionSystem.policy_knowledge.gender, "policy"),
        training: parseData(
          preventionSystem.training_knowledge.gender,
          "training"
        ),
        channel: parseData(
          preventionSystem.channel_knowledge.gender,
          "channel"
        ),
        procedure: parseData(
          preventionSystem.procedure_knowledge.gender,
          "procedure"
        ),
      });
    }
  }, [preventionSystem]);

  const [knowledgeGoals, setKnowledgeGoals] = useState<number[][]>([]);
  const [knowledgeGoalNames, setKnowledgeGoalNames] = useState<string[]>([]);

  useEffect(() => {
    if (user) {
      const goals: number[][] = [];
      const goalNames: string[] = [];
      if (user.hasRegionalBenchmark && regionalBenchmark) {
        goals.push([
          regionalBenchmark.benchmarks.RB_0001,
          regionalBenchmark.benchmarks.RB_0002,
          regionalBenchmark.benchmarks.RB_0003,
          regionalBenchmark.benchmarks.RB_0004,
        ]);
        goalNames.push("Benchmark Regional");
      }
      if (user.hasCountryBenchmark && countryBenchmark) {
        goals.push([
          countryBenchmark.benchmarks.CB_0001,
          countryBenchmark.benchmarks.CB_0002,
          countryBenchmark.benchmarks.CB_0003,
          countryBenchmark.benchmarks.CB_0004,
        ]);
        goalNames.push("Benchmark País");
      }
      if (user.hasEvolutionaryResults && previousMetrics) {
        goals.push([
          previousMetrics.metrics.COM_0001,
          previousMetrics.metrics.COM_0002,
          previousMetrics.metrics.COM_0003,
          previousMetrics.metrics.COM_0004,
        ]);
        goalNames.push("Medición anterior");
      }
      setKnowledgeGoals(goals);
      setKnowledgeGoalNames(goalNames);
    }
  }, [user, regionalBenchmark, countryBenchmark, previousMetrics]);

  const onSelectChangeKnowledge = (value: string | null) => {
    if (preventionSystem) {
      switch (value) {
        case "genero":
          setKnowledgeCategories(
            parseCategories(preventionSystem.policy_knowledge.gender)
          );
          setKnowledgeData({
            policy: parseData(
              preventionSystem.policy_knowledge.gender,
              "policy"
            ),
            training: parseData(
              preventionSystem.training_knowledge.gender,
              "training"
            ),
            channel: parseData(
              preventionSystem.channel_knowledge.gender,
              "channel"
            ),
            procedure: parseData(
              preventionSystem.procedure_knowledge.gender,
              "procedure"
            ),
          });
          break;
        case "edad":
          setKnowledgeCategories(
            parseCategories(preventionSystem.policy_knowledge.age!)
          );
          setKnowledgeData({
            policy: parseData(preventionSystem.policy_knowledge.age!, "policy"),
            training: parseData(
              preventionSystem.training_knowledge.age!,
              "training"
            ),
            channel: parseData(
              preventionSystem.channel_knowledge.age!,
              "channel"
            ),
            procedure: parseData(
              preventionSystem.procedure_knowledge.age!,
              "procedure"
            ),
          });
          break;
        case "orientacion-sexual":
          setKnowledgeCategories(
            parseCategories(
              preventionSystem.policy_knowledge.sexual_orientation!
            )
          );
          setKnowledgeData({
            policy: parseData(
              preventionSystem.policy_knowledge.sexual_orientation!,
              "policy"
            ),
            training: parseData(
              preventionSystem.training_knowledge.sexual_orientation!,
              "training"
            ),
            channel: parseData(
              preventionSystem.channel_knowledge.sexual_orientation!,
              "channel"
            ),
            procedure: parseData(
              preventionSystem.procedure_knowledge.sexual_orientation!,
              "procedure"
            ),
          });
          break;
        case "grupo-etnico-racial":
          setKnowledgeCategories(
            parseCategories(preventionSystem.policy_knowledge.ethnicity!)
          );
          setKnowledgeData({
            policy: parseData(
              preventionSystem.policy_knowledge.ethnicity!,
              "policy"
            ),
            training: parseData(
              preventionSystem.training_knowledge.ethnicity!,
              "training"
            ),
            channel: parseData(
              preventionSystem.channel_knowledge.ethnicity!,
              "channel"
            ),
            procedure: parseData(
              preventionSystem.procedure_knowledge.ethnicity!,
              "procedure"
            ),
          });
          break;
        case "puesto-de-trabajo":
          setKnowledgeCategories(
            parseCategories(preventionSystem.policy_knowledge.job)
          );
          setKnowledgeData({
            policy: parseData(preventionSystem.policy_knowledge.job, "policy"),
            training: parseData(
              preventionSystem.training_knowledge.job,
              "training"
            ),
            channel: parseData(
              preventionSystem.channel_knowledge.job,
              "channel"
            ),
            procedure: parseData(
              preventionSystem.procedure_knowledge.job,
              "procedure"
            ),
          });
          break;
        case "area":
          setKnowledgeCategories(
            parseCategories(preventionSystem.policy_knowledge.area!)
          );
          setKnowledgeData({
            policy: parseData(
              preventionSystem.policy_knowledge.area!,
              "policy"
            ),
            training: parseData(
              preventionSystem.training_knowledge.area!,
              "training"
            ),
            channel: parseData(
              preventionSystem.channel_knowledge.area!,
              "channel"
            ),
            procedure: parseData(
              preventionSystem.procedure_knowledge.area!,
              "procedure"
            ),
          });
          break;
        case "centro-de-trabajo":
          setKnowledgeCategories(
            parseCategories(preventionSystem.policy_knowledge.workplace!)
          );
          setKnowledgeData({
            policy: parseData(
              preventionSystem.policy_knowledge.workplace!,
              "policy"
            ),
            training: parseData(
              preventionSystem.training_knowledge.workplace!,
              "training"
            ),
            channel: parseData(
              preventionSystem.channel_knowledge.workplace!,
              "channel"
            ),
            procedure: parseData(
              preventionSystem.procedure_knowledge.workplace!,
              "procedure"
            ),
          });
          break;
        case "turno":
          setKnowledgeCategories(
            parseCategories(preventionSystem.policy_knowledge.work_shift!)
          );
          setKnowledgeData({
            policy: parseData(
              preventionSystem.policy_knowledge.work_shift!,
              "policy"
            ),
            training: parseData(
              preventionSystem.training_knowledge.work_shift!,
              "training"
            ),
            channel: parseData(
              preventionSystem.channel_knowledge.work_shift!,
              "channel"
            ),
            procedure: parseData(
              preventionSystem.procedure_knowledge.work_shift!,
              "procedure"
            ),
          });
          break;
      }
    }
  };

  return (
    <>
      {preventionSystem ? (
        <Flex direction="column" gap="md">
          <SectionTitle
            title="Sistema de prevención"
            description="Conocimiento por parte de las personas encuestadas sobre el sistema de prevención de tu empresa."
          />
          <Flex direction={{ base: "column", lg: "row" }} gap="xs">
            <ResultadosCard
              title="Checklist del sistema de prevención"
              description="Elementos marcados en tu encuesta organizacional."
            >
              <Center>
                <Flex direction="column" gap="xs">
                  <FilaChecklistPrevencion
                    title="Contar con una política de prevención del hostigamiento o acoso sexual laboral."
                    checked={preventionSystem.checklist.has_policy}
                  />
                  <FilaChecklistPrevencion
                    title="Contar con un canal de denuncias de hostigamiento o acoso sexual laboral."
                    checked={preventionSystem.checklist.has_channel}
                  />
                  <FilaChecklistPrevencion
                    title="Contar con un procedimiento de investigación aplicable a los casos de hostigamiento o acoso sexual laboral."
                    checked={preventionSystem.checklist.has_procedure}
                  />
                  <FilaChecklistPrevencion
                    title="Tener un Comité de Intervención, Comité de Convivencia u otra instancia especifica para investigar y resolver casos de hostigamiento o acoso sexual laboral."
                    checked={preventionSystem.checklist.has_committee}
                  />
                  <FilaChecklistPrevencion
                    title="Participar en el ecosistema de igualdad e inclusión."
                    checked={preventionSystem.checklist.belongs_to_environment}
                  />
                  <FilaChecklistPrevencion
                    title="Realizar capacitaciones a su personal en hostigamiento o acoso sexual laboral"
                    checked={preventionSystem.checklist.has_training}
                  />
                </Flex>
              </Center>
            </ResultadosCard>
            <Flex sx={{ flexBasis: "auto" }}>
              <ResultadosCard
                title="Capacitación por público"
                description="Porcentaje de capacitación según tu encuesta organizacional."
              >
                <Flex
                  align="center"
                  justify="center"
                  direction="column"
                  px="lg"
                >
                  <List mt="sm">
                    <List.Item>
                      Líderes de la organización:{" "}
                      {preventionSystem.checklist.training_by_public.leaders}
                    </List.Item>
                    <List.Item>
                      Recursos Humanos:{" "}
                      {preventionSystem.checklist.training_by_public.rrhh}
                    </List.Item>
                    <List.Item>
                      Comités:{" "}
                      {preventionSystem.checklist.training_by_public.committee}
                    </List.Item>
                    <List.Item>
                      Trabajadores/as:{" "}
                      {preventionSystem.checklist.training_by_public.employees}
                    </List.Item>
                    <List.Item>
                      Empresas contratistas:{" "}
                      {preventionSystem.checklist.training_by_public.others}
                    </List.Item>
                  </List>
                </Flex>
              </ResultadosCard>
            </Flex>
          </Flex>
          <ResultadosCard
            title="Conocimiento del sistema de prevención"
            description="Porcentaje que conoce los elementos del sistema de prevención."
          >
            <div className={classes.conocimientoContainer}>
              <Center>
                <BarChart
                  categories={[
                    ["Conoce y ha", "revisado la política"],
                    ["Recibió capacitación", "al menos una vez"],
                    ["Conoce y sabe cómo", "acceder al", "canal de denuncias"],
                    ["Conoce el procedimiento", "de investigación"],
                  ]}
                  seriesData={[
                    [
                      preventionSystem.overview.policy,
                      preventionSystem.overview.training,
                      preventionSystem.overview.channel,
                      preventionSystem.overview.procedure,
                    ],
                  ]}
                  seriesNames={["Tus resultados"]}
                  max={100}
                  height={500}
                  width={800}
                  colors={[colorCeleste1]}
                  goals={knowledgeGoals}
                  goalNames={knowledgeGoalNames}
                />
              </Center>
            </div>
          </ResultadosCard>
          <ResultadosCard
            title="Conocimiento del sistema de prevención por perfil"
            description="Porcentaje que conoce los elementos del sistema de prevención."
            titleChildren={
              <SelectCortesData
                onSelectChange={onSelectChangeKnowledge}
                genderDisabled={!preventionSystem.policy_knowledge?.gender}
                ageDisabled={!preventionSystem.policy_knowledge?.age}
                sexualOrientationDisabled={
                  !preventionSystem.policy_knowledge?.sexual_orientation
                }
                ethnicityDisabled={
                  !preventionSystem.policy_knowledge?.ethnicity
                }
                jobDisabled={!preventionSystem.policy_knowledge?.job}
                areaDisabled={!preventionSystem.policy_knowledge?.area}
                workplaceDisabled={
                  !preventionSystem.policy_knowledge?.workplace
                }
                workShiftDisabled={
                  !preventionSystem.policy_knowledge?.work_shift
                }
              />
            }
          >
            <Tabs defaultValue="politica" orientation="horizontal" mt="sm">
              <Tabs.List grow>
                <Tabs.Tab value="politica">Política</Tabs.Tab>
                <Tabs.Tab value="capacitacion">Capacitación</Tabs.Tab>
                <Tabs.Tab value="canal-de-denuncia">Canal de denuncia</Tabs.Tab>
                <Tabs.Tab value="procedimiento">Procedimiento</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="politica">
                <StackedBarChartConocimiento
                  // width={600}
                  height={550}
                  categories={knowledgeCategories || []}
                  seriesData={knowledgeData?.policy || []}
                  colors={[colorVerdeAgua1, colorAmarillo1, colorRojoClaro1]}
                />
              </Tabs.Panel>
              <Tabs.Panel value="capacitacion">
                <StackedBarChartConocimiento
                  // width={600}
                  height={550}
                  categories={knowledgeCategories || []}
                  seriesData={knowledgeData?.training || []}
                  colors={[colorVerdeAgua1, colorAmarillo1, colorRojoClaro1]}
                />
              </Tabs.Panel>
              <Tabs.Panel value="canal-de-denuncia">
                <StackedBarChartConocimiento
                  // width={600}
                  height={550}
                  categories={knowledgeCategories || []}
                  seriesData={knowledgeData?.channel || []}
                  colors={[colorVerdeAgua1, colorAmarillo1, colorRojoClaro1]}
                />
              </Tabs.Panel>
              <Tabs.Panel value="procedimiento">
                <StackedBarChartConocimiento
                  // width={600}
                  height={550}
                  categories={knowledgeCategories || []}
                  seriesData={knowledgeData?.procedure || []}
                  colors={[colorVerdeAgua1, colorRojoClaro1]}
                />
              </Tabs.Panel>
            </Tabs>
          </ResultadosCard>
          <Flex direction={{ base: "column", md: "row" }} gap="md">
            <ResultadosCard
              title="Canales de denuncia según el personal"
              description="Porcentaje que identifica estos canales de denuncia."
              // sx={{ width: "50%" }}
            >
              <Flex align="center" justify="space-evenly">
                <BarChart
                  max={100}
                  height={400}
                  // height={300}
                  width={800}
                  // width={500}
                  colors={[colorAzul1]}
                  categories={Object.keys(identifiedChannels)}
                  seriesData={[Object.values(identifiedChannels)]}
                />
              </Flex>
            </ResultadosCard>
            <ResultadosCard
              title="Canales de denuncia según la empresa"
              description="Canales identificados en tu encuesta organizacional."
              alignSelf="flex-start"
              // alignSelf="center"
              // sx={{ width: "50%" }}
            >
              <Flex justify="center">
                <List>
                  {preventionSystem.actual_channels.map((item) => (
                    <List.Item key={item}>{item}</List.Item>
                  ))}
                </List>
              </Flex>
            </ResultadosCard>
          </Flex>
        </Flex>
      ) : (
        <GenderLabLoader />
      )}
    </>
  );
};
