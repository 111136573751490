import Chart from "react-apexcharts";
import { Flex } from "@mantine/core";

type Props = {
  height?: number;
  width?: number;
  colors: string[];
  responsive?: any[];
  title?: string;
  xaxisTitle?: string;
  categories: string[];
  seriesData: any[];
  seriesNames?: string[];
  horizontal?: boolean;
  justify?: string;
};

export const StackedBarChartConocimiento = ({
  height = 450,
  width,
  colors,
  responsive = [
    {
      breakpoint: 700,
      options: {
        chart: {
          width: 300,
        },
      },
    },
  ],
  title = "",
  xaxisTitle = "Porcentaje (%)",
  categories,
  seriesData,
  seriesNames = ["Porcentaje %"],
  horizontal = true,
  justify = "center",
}: Props) => {
  return (
    // <Flex justify={justify}>
    <Chart
      type="bar"
      height={height}
      width={width}
      series={seriesData}
      options={{
        chart: {
          type: "bar",
          height: height,
          stacked: true,
          stackType: "100%",
        },
        colors: colors,
        responsive,
        title: {
          text: title,
        },
        plotOptions: {
          bar: {
            horizontal: horizontal,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          title: {
            text: xaxisTitle,
          },
          categories: categories,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " personas";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 40,
        },
        states: {
          hover: {
            filter: {
              type: "darken",
              value: 0.75,
            },
          },
        },
      }}
    />
    // </Flex>
  );
};
